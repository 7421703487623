$mob: 520px;

.root {
  z-index: 2;
  position: relative;
  width: fit-content;

  @media (min-width: #{$mob + 1}) {
    margin-top: 12px;
  }
}

.btn {
  padding: 4px 8px;

  border-radius: 4px;
  background: var(--thm-grey-1);

  color: var(--thm-blue);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4px;

  transition: 0.2s background-color ease;

  @media (max-width: $mob) {
    display: none;
  }
}

.dropdown_wrap > div {
  > ul {
    border-radius: 24px !important;
  }
}

.dropdown_root {
  width: 378px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36px;

  color: var(--thm-black);
}

.discounts {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.available_discounts {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;

    color: var(--thm-silver-grey);
  }

  ul {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  li {
    padding: 8px 12px;
    border-radius: 8px;

    background-color: var(--thm-grey-1);

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-black);
  }
}
