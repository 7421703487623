.lead_row {
  z-index: 1;

  > svg {
    flex-shrink: 0;
    color: var(--thm-silver-grey);

    :global(html[dir='rtl']) & {
      transform: scaleX(-1);
    }
  }
}

.lead_row,
.lead_customer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lead_type {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 8px;

  border-radius: 4px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.lead_customer {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.ad_stats {
  display: flex;
  align-items: center;

  > li {
    position: relative;
    white-space: nowrap;

    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-black);

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(:first-child) {
      padding-left: 20px;

      :global(html[dir='rtl']) & {
        padding-left: unset;
        padding-right: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;

        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--thm-silver-grey-1);

        :global(html[dir='rtl']) & {
          left: unset;
          right: 8px;
        }
      }
    }
  }
}

.label {
  position: absolute;
  top: 8px;
  left: 8px;

  padding: 2px 6px;

  border-radius: 4px;
  background: #0000007a;

  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  :global(html[dir='rtl']) & {
    left: unset;
    right: 8px;
  }
}
