.root {
  position: relative;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    top: -16px;
    left: -16px;
    width: calc(100% + 32px);
    height: calc(100% + 32px);

    background: var(--thm-white);
    box-shadow: var(--shadow-primary);
    border: 1px solid var(--thm-grey-1);
    border-radius: 16px;
    opacity: 0;
    transition: 0.2s ease;
    pointer-events: none;
  }

  &:hover {
    z-index: 6;

    .actions,
    button.stats_row,
    .site_link,
    .edit_btn,
    &::before,
    .dates {
      opacity: 1;
    }

    .actions,
    .link {
      pointer-events: auto;
    }

    .name {
      color: var(--clr-primary);
    }

    .img {
      background: var(--thm-card);
    }
  }
}

.root_gap {
  gap: 24px;
}

.advert {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
}

.img {
  position: relative;
  overflow: hidden;
  min-width: 234px;
  width: 234px;

  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);
  background: var(--thm-white);
  transition: background 0.2s ease;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;
  }

  > span:not(.status) {
    display: block;
    height: 0;
  }
}

.img_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.status {
  position: absolute;
  top: 4px;
  left: 4px;

  padding: 4px 6px;

  background: var(--clr-primary);
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--clr-white);

  &_green {
    background: var(--clr-green);
  }

  &_orange {
    background: var(--clr-orange);
  }
}

.info {
  flex: 1;
  min-width: 260px;
  padding-left: 8px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.name_wrap {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  position: relative;
}

.name {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4px;
  transition: 0.2s ease;
}

.color {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  border: 1px solid var(--thm-photo-stroke);
}

.specs {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;

  span {
    color: var(--thm-silver-grey);
  }
}

.site_link {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--clr-blue);

  opacity: 0;
  transition: 0.2s ease;
}

.edit_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.2s ease;

  &,
  svg {
    width: 16px;
    height: 16px;
  }
}

.days {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  b {
    font-weight: 600;
    letter-spacing: -0.5px;
    color: var(--thm-black);
  }
}

.days_desktop {
  min-width: 180px;

  @media (max-width: 1480px) {
    min-width: 156px;
  }
}

.days_desktop {
  @media (max-width: 1480px) {
    display: none;
  }
}

.days_mob {
  margin-top: 12px;

  @media (min-width: 1481px) {
    display: none;
  }
}

.stats {
  min-width: 112px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  > li:not(:last-child) {
    margin-bottom: 8px;
  }

  .stats_row {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  button.stats_row {
    color: var(--clr-blue);
    opacity: 0;
    transition: 0.2 ease;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.actions {
  min-width: 40px;

  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease;
}

.tariffs {
  width: 320px;
}

.right_col {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.actions,
button.stats_row,
.edit_btn {
  position: relative;
  z-index: 1;
}

.promotions {
  transform: translateY(-1px);
  margin-top: auto;
  padding-top: 12px;

  position: relative;
  z-index: 1;
}

.dates {
  position: absolute;
  bottom: 0;
  right: 56px;

  color: var(--thm-silver-grey);
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  text-align: right;

  opacity: 0;
  transition: opacity 0.2s ease;

  :global(html[dir='rtl']) & {
    right: unset;
    left: 56px;
  }
}
