.recognition {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;

  color: var(--clr-blue);

  :global(html[dir='rtl']) & {
    right: unset;
    left: 12px;
  }
}
