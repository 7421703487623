$desktop-lg: 1630px;
$desktop: 1265px;
$tab: 770px;

.label {
  display: block;
  margin-bottom: 12px;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  display: block;
  margin-top: 12px;
}

.list {
  @media (min-width: #{$desktop + 1}) {
    min-width: 230px;
    width: 230px;
  }

  @media (min-width: #{$tab + 1}) {
    min-width: 200px;
    width: 200px;
  }
}

.row {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
  text-align: left;

  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 8px;

  transition: 0.2s ease;
  border-radius: 8px;

  &_active:not(.row_select),
  &:hover {
    background: var(--thm-grey-1);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .img_placeholder,
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &:not(.row_all_select):not(.row_select) {
    svg {
      margin-left: auto;
      min-width: 12px;
      width: 12px;

      color: var(--thm-silver-grey-1);

      :global(html[dir='rtl']) & {
        margin-left: unset;
        margin-right: auto;

        transform: scaleX(-1);
      }
    }
  }

  &_select {
    svg {
      min-width: 20px;
      width: 20px;
      height: 20px;
      color: var(--clr-blue);
      visibility: hidden;
    }

    &.row_active svg {
      visibility: visible;
    }
  }

  &_all_select {
    position: relative;
    margin-bottom: 9px;

    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 8px;
      width: calc(100% - 16px);
      height: 1px;
      background: var(--thm-grey-1);
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--thm-silver-grey);
    }
  }
}

.generations {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  > li {
    width: calc(33.33% - 6px);
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: $desktop-lg) {
      width: calc(50% - 4px);
    }

    @media (max-width: $desktop) {
      width: 100%;
    }
  }
}

.root_range {
  width: 363px;
  padding: 2px;
}

.chart {
  width: 100%;
  margin-top: 24px;
  margin-bottom: -16px;
}

.range {
  width: 100%;
  margin-bottom: 24px;

  > div > div:last-child {
    display: none;
  }

  &_chart {
    pointer-events: none;

    :global {
      .range-slider-thumb {
        pointer-events: auto;
      }

      .range-slider-bar,
      .range-slider-gradient,
      .range-slider-progress {
        visibility: hidden;
      }
    }
  }
}

.fields {
  display: flex;
  border: 1px solid var(--thm-grey-1);
  border-radius: 8px;

  > div {
    width: 50%;

    input:hover,
    input:focus {
      position: relative;
      z-index: 1;
    }

    input + span {
      border: 0 !important;
      border-radius: 0;
    }

    &:first-child {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid var(--thm-grey-1);
      }
    }

    &:last-child {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.empty {
  width: 230px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.check_icon {
  display: flex;
  align-items: center;
  margin-left: auto;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }

  svg {
    color: var(--clr-blue) !important;
    width: 20px !important;
    height: 20px;
  }
}
