$tab: 770px;
$mob: 430px;

.root {
  background: var(--thm-white);
  border-radius: 12px;

  padding: 8px;
  position: relative;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.label {
  position: absolute;
  top: 2px;
  left: 2px;

  padding: 2px 4px;

  border-radius: 2px;
  background: #0000007a;

  font-weight: 500;
  font-size: 10px;
  line-height: 1;

  :global(html[dir='rtl']) & {
    left: unset;
    right: 8px;
  }
}

.img {
  min-width: 87px;
  width: 87px;
  height: 66px;

  overflow: hidden;
  position: relative;

  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
}

.price {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: var(--thm-silver-grey);
}

.status_wrap {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &:empty {
    display: none;
  }
}

.status {
  padding: 2px 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--thm-silver-grey);

  border-radius: 4px;
  background: var(--thm-grey-1);
}

.view_badge {
  width: 12px;
  height: 12px;

  background: var(--clr-primary);
  border-radius: 50%;
}

.info {
  position: relative;
  flex: 1;
}

.lead_row {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 4px;
  margin-top: 8px;

  border-radius: 4px;
  background: var(--thm-grey-1);

  svg {
    width: 12px;
    height: 12px;

    :global(html[dir='rtl']) & {
      transform: scaleX(-1);
    }
  }
}

.lead_type {
  display: flex;
  align-items: center;
  gap: 2px;
}

.lead_contact {
  color: var(--thm-silver-grey);
  max-width: 132px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lead_type,
.lead_contact {
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.06px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 8px;

  border-top: 0.5px solid var(--thm-grey-1);
  margin-top: 8px;
  padding-top: 8px;
}

.customer_name {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $tab) {
    max-width: 120px;
  }
}

.date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.actions {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }

  a svg {
    width: 20px;
    height: 20px;
  }

  > a {
    width: 36px;
  }
}

.phone {
  a {
    @media (max-width: $mob) {
      width: 36px;
    }

    span {
      @media (max-width: $mob) {
        display: none;
      }
    }
  }
}

.link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  :global(html[dir='rtl']) & {
    left: unset;
    right: 0;
  }
}
