$mob: 770px;

.root {
  margin-top: 12px;
}

.list_wrap {
  margin-top: 16px;
}

.list {
  @media (min-width: #{$mob + 1}) {
    display: flex;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-left: -12px;
  }

  > li {
    @media (min-width: #{$mob + 1}) {
      margin-top: 8px;
      margin-left: 12px;
      width: calc(25% - 12px);
    }
  }
}

.toggler {
  .model_btn {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--thm-silver-grey);
  }

  svg {
    transition: transform 0.2s ease;
  }
}

.toggler_opened svg {
  transform: rotate(180deg);
}

.model_btn {
  width: 100%;
  padding: 8px 12px;

  text-align: left;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  border-radius: 8px;
  transition: 0.2s ease;

  :global(html[dir='rtl']) & {
    text-align: right;
  }

  &:hover {
    background: var(--clr-blue-15);
    color: var(--clr-blue);
  }
}

.input_text {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  display: block;
  padding-top: 4px;
  padding-left: 12px;
}
