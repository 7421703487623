.root {
  position: relative;

  :global {
    .react-datepicker {
      z-index: 1;
      font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, sans-serif;

      border: 0;
      background: var(--thm-card);
    }

    // Header, month
    .react-datepicker__header {
      background: 0;
      border: 0;
      padding: 24px 0 0;
    }

    .react-datepicker__month {
      margin: 0;
      padding: 0 24px 24px;
    }

    // Weekday names
    .react-datepicker__day-names {
      margin: 16px 0 8px;
      line-height: 1;
    }

    .react-datepicker__day-name {
      margin: 0;
      width: 36px;
      height: 36px;

      border-bottom: 1px solid var(--thm-grey-1);

      font-weight: 500;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: var(--thm-black);

      &:nth-last-child(1),
      &:nth-last-child(2) {
        color: var(--clr-primary);
      }
    }

    // Navigation
    .react-datepicker__navigation,
    .react-datepicker__triangle {
      display: none;
    }

    // Week
    .react-datepicker__week {
      display: flex;
    }

    // Day
    .react-datepicker__day--outside-month {
      color: var(--thm-black);
      opacity: 0.35;
    }

    .react-datepicker__day--today {
      background: var(--clr-blue-15);
    }

    .react-datepicker__day--weekend {
      color: var(--clr-primary);
    }

    .react-datepicker__day {
      border-radius: 24px;
      outline: 0;

      // Hover
      &:not(.react-datepicker__day--in-range):not(
          .react-datepicker__day--selected
        ):not(.react-datepicker__day--keyboard-selected):hover {
        background: var(--thm-grey-2);
      }

      &--disabled {
        opacity: 0.35;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range {
      background: var(--clr-blue);
      color: var(--clr-white);
    }

    .react-datepicker__day--in-range {
      border-radius: 0;
    }

    .react-datepicker__day--in-selecting-range {
      background: var(--thm-grey-1);
      color: var(--thm-black);
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--in-range:first-child:not(
        .react-datepicker__day--range-end
      ) {
      border-radius: 24px 0 0 24px;

      :global(html[dir='rtl']) & {
        border-radius: 0 24px 24px 0;
      }
    }

    .react-datepicker__day--in-range:last-child,
    .react-datepicker__day--range-end {
      border-radius: 0 24px 24px 0;

      :global(html[dir='rtl']) & {
        border-radius: 24px 0 0 24px;
      }
    }

    .react-datepicker__day--range-start.react-datepicker__day--range-end,
    .react-datepicker__day--range-start:last-child,
    .react-datepicker__day--range-end:first-child {
      border-radius: 24px;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background: var(--thm-grey-2);
      color: var(--thm-card);
    }

    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle {
      &::before {
        border-bottom-color: rgba(9, 30, 66, 0.1);
      }

      &::after {
        border-bottom-color: var(--thm-card);
      }
    }

    .react-datepicker__input-time-container {
      margin: 0;
      width: 100%;
      padding: 0 16px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .react-datepicker-time__caption {
      color: var(--thm-black);
      font-weight: 600;
      font-size: 14px;
      line-height: 1;
    }

    .react-datepicker-time__input {
      margin-left: 12px;

      input.react-datepicker-time__input {
        margin: 0;
        padding: 6px 12px;
        display: flex;
        align-items: center;

        border-radius: 8px;
        background: var(--thm-grey-2);
        color: var(--thm-black);

        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }
    }
  }
}

.label {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.4;
  color: var(--thm-black);
  text-align: left;

  display: block;
  margin-bottom: 4px;
}

.field {
  width: 100%;
  padding: 12px 16px;
  min-height: 44px;

  transition: 0.2s ease;
  border-radius: 8px;

  border: 0;
  -webkit-appearance: none;
  background: var(--thm-card);
  box-shadow: 0px 1px 1px rgba(10, 10, 11, 0.06),
    inset 0px 0px 0px 1px rgba(10, 10, 46, 0.16);

  font-size: 14px;
}

.root_only_time {
  :global {
    .react-datepicker {
      padding-top: 16px;
    }

    .react-datepicker__month-container,
    .react-datepicker__navigation {
      display: none;
    }
  }
}

.root_double {
  :global {
    .react-datepicker {
      display: flex;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  min-height: 32px;

  button {
    min-width: 32px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    transition: 0.2s ease;

    color: var(--thm-black);

    &:hover:not(:disabled) {
      background: var(--thm-grey-2);
    }

    &:last-child svg {
      transform: scale(-1);

      :global(html[dir='rtl']) & {
        transform: scaleX(1);
      }
    }

    svg {
      width: 16px;
      height: 16px;

      :global(html[dir='rtl']) & {
        transform: scaleX(-1);
      }
    }
  }

  span {
    font-weight: 500;
    font-size: 16px;
    color: var(--thm-black);
    text-transform: capitalize;
  }
}

.day {
  margin: 0;
  width: 36px;
  height: 36px;

  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  color: var(--thm-black);

  &:empty {
    visibility: hidden;
  }
}
