$tab: 770px;

.root {
  position: relative;
  z-index: 1;

  margin-top: 24px;

  @media (max-width: $tab) {
    margin-top: 8px;
  }
}

.title {
  margin-bottom: 12px;
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);

  margin-bottom: 24px;
}

.list_wrap {
  margin-bottom: 12px;

  @media (max-width: $tab) {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0 24px;

    overflow-y: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    :global(html[dir='rtl']) & {
      margin-left: unset;
      margin-right: -24px;
    }
  }
}

.list {
  display: flex;
  margin-left: -12px;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: -12px;
  }

  > li {
    width: calc(33.33% - 12px);
    margin-left: 12px;

    :global(html[dir='rtl']) & {
      margin-left: unset;
      margin-right: 12px;
    }

    @media (max-width: $tab) {
      min-width: 217px;
      width: auto;
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translate(-50%, 100%);

  :global(html[dir='rtl']) & {
    left: unset;
    right: 50%;
  }
}

.package {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.package,
.free_btn {
  border-radius: 16px;
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);
  transition: border-color 0.2s ease;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 16px;
    border: 2px solid var(--thm-black);
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }

  &_active {
    &::before {
      border: 2px solid var(--clr-green);
      opacity: 1;
    }
  }

  &_selected {
    border: 1px solid rgba(0, 0, 0, 0);

    &::before {
      opacity: 1;
    }
  }
}

.package_btn {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  :global(html[dir='rtl']) & {
    left: unset;
    right: 0;
  }
}

.header {
  width: 100%;
  gap: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
}

.name {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.38px;
  display: block;
}

.duration {
  font-size: 12px;
  line-height: 16px;
  color: var(--thm-silver-grey);
}

.icon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.options {
  width: 100%;
  margin-bottom: 16px;

  > li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.option {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;

  &_included {
    color: var(--thm-black);
  }

  svg {
    min-width: 16px;
    width: 16px;
    height: 16px;
  }
}

.price_wrap {
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid var(--thm-grey-1);

  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-left: auto;

    :global(html[dir='rtl']) & {
      margin-left: unset;
      margin-right: auto;
    }
  }
}

.price {
  white-space: nowrap;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.408px;
}

.old_price {
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  text-decoration: line-through;
  color: var(--thm-silver-grey);
}

.free_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 52px;
  padding: 16px 14px 16px 16px;

  :global(html[dir='rtl']) & {
    padding: 16px 16px 16px 14px;
  }
}

.free_btn_row {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

// Auto ext
.autoext {
  position: relative;

  margin-bottom: 12px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  border-radius: 16px;
  background: var(--thm-grey-1);

  > button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}

.autoext_text {
  p {
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.408px;
  }

  small {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}
