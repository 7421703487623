.root {
  z-index: 100;
  position: sticky;
  top: var(--header-h);

  min-height: 52px;
  width: calc(100% + var(--main-content-padding-x) * 2);
  margin-left: calc(-1 * var(--main-content-padding-x));
  padding: 0 var(--main-content-padding-x);
  margin-bottom: 24px;
  gap: 24px;
  overflow-x: auto;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  background: var(--thm-white);

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: calc(-1 * var(--main-content-padding-x));
  }

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }

  > button {
    align-self: center;
  }
}
