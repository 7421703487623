.root {
  transition: 0.2s ease;
  background: var(--thm-white);
  border: 1px solid var(--thm-card);

  border-radius: 12px;

  padding: 16px 24px;
}

.root_open {
  background: var(--thm-card);
  border: 1px solid var(--thm-grey-1);

  .chevron {
    transform: rotate(90deg);

    :global(html[dir='rtl']) & {
      transform: rotate(-90deg);
    }
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon,
.chevron,
.corner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chevron {
  transition: transform 0.2s ease;
}

.corner {
  width: 24px;
  height: 24px;
  color: var(--thm-grey-2);

  :global(html[dir='rtl']) & {
    svg {
      transform: scaleX(-1);
    }
  }
}

.icon {
  width: 28px;
  height: 28px;
}

.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;
}

.chevron {
  margin-left: auto;
  color: var(--thm-silver-grey);

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;

    svg {
      transform: scaleX(-1);
    }
  }
}

.header_btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
}

.option {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
