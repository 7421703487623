$desktop-lg: 1544px;
$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.root {
  margin-top: -24px;

  --aside-w: 413px;

  @media (max-width: $desktop-lg) {
    --aside-w: 350px;
  }
}

.container {
  display: flex;
  gap: 24px;
  max-width: 1600px;

  @media (max-width: $desktop) {
    gap: 16px;
  }

  @media (max-width: $desktop-sm) {
    flex-direction: column-reverse;
    align-items: stretch;

    > * {
      flex: 1;
    }
  }

  .content,
  .aside {
    @media (max-width: $desktop-sm) {
      min-width: unset;
      width: 100%;
    }
  }

  &_adverts {
    padding: 0 24px;
    margin-top: 36px;
    align-items: flex-start;

    @media (max-width: $desktop) {
      padding-right: 0;
      flex-direction: row;
      width: 100%;
    }

    .content {
      width: unset;
    }
  }
}

.content {
  width: calc(100% - var(--aside-w) - 24px);
}

.aside {
  min-width: var(--aside-w);
  width: var(--aside-w);

  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $desktop-sm) {
    padding-top: 0 !important;
  }

  > div {
    @media (max-width: $desktop-sm) {
      min-height: unset !important;
    }
  }
}

.tabs {
  z-index: 100;
  position: sticky;
  top: var(--header-h);

  width: calc(100% + var(--main-content-padding-x) * 2);
  margin: 0 0 24px calc(-1 * var(--main-content-padding-x));
  padding: 16px 0 0 var(--main-content-padding-x);
  background: var(--thm-white);

  :global(html[dir='rtl']) & {
    margin: 0 calc(-1 * var(--main-content-padding-x)) 24px 0;
    padding: 16px var(--main-content-padding-x) 0 0;
  }

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}

.type_tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);

    @media (max-width: $desktop-sm) {
      display: none;
    }
  }

  button {
    white-space: nowrap;
  }
}

.debit_wrap {
  @media (min-width: #{$tab + 1}) {
    padding: 0 16px;
  }
}

.view_types {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  max-width: 1600px;
  margin-top: 36px;
  margin-bottom: 16px;

  @media (max-width: $desktop-sm) {
    max-width: unset;
    justify-content: flex-start;
    gap: 16px;

    width: calc(100% + var(--main-content-padding-x) * 2);
    margin: 16px 0 16px calc(-1 * var(--main-content-padding-x));
    padding-left: var(--main-content-padding-x);
    overflow-x: auto;

    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.search_input {
  @media (min-width: #{$desktop-sm + 1}) {
    max-width: 236px;
    width: 100%;
  }

  @media (max-width: $desktop-sm) {
    min-width: 236px;
    width: 236px;
  }
}

.total {
  margin-left: auto;

  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.35px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.li {
  position: relative;
}
