.root {
  margin-top: -24px;
}

.header {
  z-index: 3;
  position: sticky;
  top: var(--header-h);

  width: calc(100% + 48px);
  margin: 0 0 24px -24px;
  padding: 16px 0 0 24px;
  background: var(--thm-white);

  :global(html[dir='rtl']) & {
    margin: 0 -24px 24px 0;
    padding: 16px 24px 0 0;
  }

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}
