.modal_container {
  background: var(--thm-bg);
}

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-top: 24px;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  align-items: center;
  gap: 8px;

  > div {
    flex: 1;
    width: 50%;
  }
}

.select_btn {
  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;
  border-radius: 8px;

  &_icon,
  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &_icon {
    color: var(--clr-blue);
  }

  &_inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;

    span,
    small {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    small {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
      color: var(--thm-silver-grey);
    }
  }

  &_icon,
  button:not(.select_btn_inner) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:not(.select_btn_inner) {
    margin-left: auto;
    opacity: 0;
    pointer-events: none;
    transition: 0.15s ease;
    color: var(--thm-silver-grey);

    position: relative;
    z-index: 1;
  }

  &:hover button:not(.select_btn_inner) {
    opacity: 1;
    pointer-events: auto;
  }
}
