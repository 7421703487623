$desktop: 1300px;
$desktop-sm: 1024px;
$tab: 770px;

.root {
  --summary-w: 416px;
  --row-gap: 24px;
  margin-top: -24px;

  @media (max-width: $desktop) {
    --summary-w: 346px;
    --row-gap: 16px;
  }
}

.container {
  max-width: 1600px;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: #{$tab + 1}) {
    height: calc(100vh - var(--header-h) - var(--navbar-h) - 56px);
    height: calc(
      var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h) - 56px
    );
  }

  > div:not(.spinner) {
    max-width: 420px;
    width: 100%;
  }
}

.filters {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;

  @media (max-width: $desktop) {
    margin-bottom: 28px;

    overflow-x: auto;
    width: calc(100% + var(--main-content-padding-x) * 2);
    margin-left: calc(-1 * var(--main-content-padding-x));
    padding: 0 var(--main-content-padding-x);

    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

    :global(html[dir='rtl']) & {
      margin-left: unset;
      margin-right: calc(-1 * var(--main-content-padding-x));
    }
  }
}

.filter_flex {
  @media (min-width: #{$desktop + 1}) {
    flex: 1;
  }

  @media (max-width: $desktop) {
    min-width: 200px;
    width: 200px;
  }

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;
  }
}

.filter_date > div {
  > button {
    min-width: unset;
    @media (max-width: $desktop) {
      width: 100%;
    }
  }
}

.sort_dropdown {
  > ul button {
    span {
      max-width: unset;
    }
  }
}

.row {
  width: 100%;
  display: flex;
  gap: var(--row-gap);

  @media (max-width: $desktop) {
    flex-direction: column-reverse;
  }

  @media (min-width: #{$desktop + 1}) {
    align-items: flex-start;
  }
}

.list_wrap {
  @media (min-width: #{$desktop + 1}) {
    width: calc(100% - var(--summary-w) - var(--row-gap));
  }
}

.summary {
  @media (min-width: #{$desktop + 1}) {
    width: var(--summary-w);
  }
}

.tabs {
  z-index: 100;
  position: sticky;
  top: var(--header-h);

  width: calc(100% + var(--main-content-padding-x) * 2);
  margin-left: calc(-1 * var(--main-content-padding-x));
  padding: 16px var(--main-content-padding-x) 0;
  margin-bottom: 24px;
  overflow-x: auto;

  background: var(--thm-white);

  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: calc(-1 * var(--main-content-padding-x));
  }

  &::before {
    content: '';
    position: absolute;

    bottom: 0;
    left: 0;

    width: 100%;
    height: 1px;
    background: var(--thm-grey-1);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}

.reply_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-black);
  white-space: nowrap;

  height: 36px;
  padding: 4px 8px 4px 12px;
  border-radius: 8px;
  box-shadow: 0 0 0 1px var(--thm-grey-1) inset;
  background-color: var(--thm-white);

  transition: 0.2s ease all;

  &:hover {
    box-shadow: 0 0 0 1px var(--clr-blue) inset;
  }

  svg {
    color: var(--clr-primary);
  }

  label {
    pointer-events: none;
  }
}
