$tab: 770px;
$mob: 500px;

.modal_container.modal_container_imp {
  @media (max-width: $tab) {
    padding: 16px;
  }
}

.root {
  @media (min-width: #{$tab + 1}) {
    width: 528px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: $tab) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;

    border-bottom: 1px solid var(--thm-grey-1);
  }
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33.41px;
  letter-spacing: 0.36px;

  @media (max-width: $tab) {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.38px;
  }
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $tab) {
    display: none;
  }

  &,
  svg {
    width: 28px;
    height: 28px;
  }
}

.socials {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;

  @media (max-width: $tab) {
    justify-content: space-between;
    margin-bottom: 16px;
  }

  > li {
    @media (min-width: #{$tab + 1}) {
      flex: 1;
    }

    @media (max-width: $tab) {
      width: 120px;
    }

    @media (max-width: $mob) {
      width: 72px;
    }
  }
}

.social {
  padding: 8px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  font-size: 17px;
  font-weight: 500;
  line-height: 23.8px;
  letter-spacing: -0.41px;
  text-align: center;
  color: var(--thm-black);

  @media (max-width: $mob) {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
  }

  svg {
    width: 60px;
    height: 60px;

    @media (max-width: $mob) {
      width: 48px;
      height: 48px;
    }
  }
}

.copy_row {
  position: relative;
  padding: 4px 4px 4px 16px;
  display: flex;
  align-items: center;

  border-radius: 16px;
  background: var(--thm-grey-1);

  @media (max-width: $mob) {
    padding-left: 12px;
    border-radius: 12px;
  }

  > svg {
    margin-right: 8px;
    min-width: 24px;
    width: 24px;
    height: 24px;
    color: var(--thm-silver-grey);

    @media (max-width: $mob) {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }
}

.copy_text {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 56%;

  @media (max-width: $mob) {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    max-width: 46%;
  }
}

.copy_btn {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy_click {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  opacity: 0;
}
