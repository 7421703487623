$tab: 770px;

// Root
.root {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.root_system {
  align-self: center;
}

.root_system,
.root_margin {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.root:not(.root_system) {
  min-width: 120px;
  max-width: 85%;

  @media (min-width: #{$tab + 1}) {
    max-width: 600px;
  }

  &.root_edited {
    min-width: 160px;
  }

  &:not(.root_tail) {
    &.root_own .message {
      border-bottom-right-radius: 4px;

      :global(html[dir='rtl']) & {
        border-bottom-right-radius: 17px;
        border-bottom-left-radius: 4px;
      }
    }

    &:not(.root_own) .message {
      border-bottom-left-radius: 4px;

      :global(html[dir='rtl']) & {
        border-bottom-left-radius: 17px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.root_media,
.root_loc {
  width: 80%;
}

.root_actions {
  .message {
    box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.1);
    transform: scale(1.04);
  }

  &.root_media,
  &.root_loc {
    .message {
      transform: scale(1.01);
    }
  }
}

// System
.system_text {
  padding: 4px 8px;

  background: rgba(69, 69, 69, 0.24);
  border-radius: 6px;

  text-align: center;
  color: var(--clr-white);
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
}

// Message
.message {
  position: relative;

  background: var(--thm-white);
  border-radius: 17px;
  transition: 0.2s ease;
  transform-origin: 50% 50%;

  padding: 2px;
}

.text {
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  white-space: pre-wrap;
  word-break: break-word;

  padding: 0 6px 0 10px;

  &_media {
    padding-top: 6px;
  }

  &:first-child {
    padding-top: 4px;
  }

  a {
    color: var(--clr-blue);
  }
}

.bottom {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;

  &:not(.bottom_float) {
    padding: 0 6px 4px;
  }

  i {
    font-style: italic;
    font-size: 11px;
    line-height: 13px;
    color: var(--thm-silver-grey);
  }
}

.bottom_float {
  padding: 4px 8px;

  position: absolute;
  bottom: 8px;
  right: 8px;

  pointer-events: none;
  background: rgba(0, 0, 0, 0.36);
  backdrop-filter: blur(4px);
  border-radius: 20px;

  &,
  i,
  .read_icon {
    color: var(--clr-white);
  }
}

.wait_icon {
  min-width: 16px;
  width: 16px;
  min-height: 16px;

  margin-left: 2px;

  &,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.read_icon {
  max-width: 29px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: -4px;
  margin-right: -4px;
}

.observer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}

.text,
.bottom {
  @media (hover: none) and (pointer: coarse) {
    user-select: none;
  }
}

.edited {
  margin-right: 2px;
}

// Tail
.tail {
  position: absolute;
  bottom: -2px;
  right: 100%;
  transform: translateX(50%);

  color: var(--thm-white);

  display: block;
  width: 13px;

  :global(html[dir='rtl']) & {
    right: unset;
    left: 100%;

    transform: scaleX(-1) translateX(50%);
  }
}

// Own
.root_own {
  margin-left: auto;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }

  .message {
    background: var(--clr-blue);

    .text {
      color: var(--clr-white);
    }
  }

  .tail {
    color: var(--clr-blue);
    left: 100%;
    transform: translateX(-50%);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 100%;
      transform: scaleX(-1) translateX(-50%);
    }
  }

  .bottom:not(.bottom_float) {
    i,
    .edited {
      color: var(--clr-onbubble);
    }
  }
}

// Highlight message
.message_highlight {
  position: absolute;
  left: 0;

  width: 100%;
  background: var(--clr-blue-30);

  transition: 0.3s ease-out;
  pointer-events: none;
  opacity: 0;

  &_active {
    opacity: 1;
    transition: 0.2s ease-in;
  }
}

// data-msg-actions
.root_own + .root_own,
.root_own + div[data-msg-actions] + .root_own,
.root_own + div[data-msg-actions] + .message_highlight + .root_own {
  .message {
    border-top-right-radius: 4px;

    :global(html[dir='rtl']) & {
      border-top-right-radius: 17px;
      border-top-left-radius: 4px;
    }
  }
}

.root_companion + .root_companion,
.root_companion + div[data-msg-actions] + .root_companion,
.root_companion + div[data-msg-actions] + .message_highlight + .root_companion {
  .message {
    border-top-left-radius: 4px;

    :global(html[dir='rtl']) & {
      border-top-left-radius: 17px;
      border-top-right-radius: 4px;
    }
  }
}
