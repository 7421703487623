$mob: 770px;

.root {
  &:not(.root_modal) {
    z-index: 5;
    position: absolute;
    left: 0;
    top: calc(100% + 4px);

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }

    .inner {
      background: var(--thm-white);
      border: 1px solid var(--thm-grey-1);
      border-radius: 12px;

      max-height: 358px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.root_open:not(.root_modal) {
  opacity: 1;
  pointer-events: auto;
}

.inner {
  display: flex;
  align-items: flex-start;

  @media (max-width: $mob) {
    display: block;
  }
}

.picker {
  margin: 4px;

  // @media (min-width: #{$mob + 1}) {
  //   border-left: 1px solid var(--thm-grey-1);
  // }

  @media (max-width: $mob) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .react-datepicker__month-container {
      @media (min-width: #{$mob + 1}) {
        border-left: 1px solid var(--clr-grey3);
      }
    }
  }
}

.buttons_wrap {
  height: 100%;

  @media (max-width: $mob) {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 0 24px;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.buttons {
  @media (max-width: $mob) {
    display: flex;
    margin-bottom: 16px;
  }

  button {
    padding: 12px;
    min-height: 44px;

    border-radius: 8px;
    transition: 0.2s ease;

    font-size: 14px;
    line-height: 20px;
    text-align: left;

    :global(html[dir='rtl']) & {
      text-align: right;
    }

    @media (min-width: #{$mob + 1}) {
      min-width: 187px;
    }

    @media (max-width: $mob) {
      white-space: nowrap;
    }

    &:hover {
      background: var(--clr-grey1);
    }
  }
}
