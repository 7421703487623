$desktop: 1200px;
$tab: 770px;

.root {
  --chat-page-padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: var(--chat-page-padding);

  height: calc(100vh - var(--header-h) - var(--navbar-h));
  height: calc(var(--vh, 1vh) * 100 - var(--header-h) - var(--navbar-h));

  @media (max-width: $tab) {
    padding: 24px 0 0 0;
  }
}

.inner,
.left,
.right {
  height: 100%;
}

.inner {
  display: flex;
  gap: 24px;
  overflow: visible;

  max-height: calc(100% - var(--chat-page-padding) * 2);

  @media (max-width: $tab) {
    position: relative;
  }
}

.left {
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  width: 50%;

  @media (max-width: $tab) {
    width: 100%;
  }
}

.placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  padding: 16px;

  b,
  p {
    font-size: 17px;
    line-height: 23.8px;
    letter-spacing: -0.41px;
    text-align: center;
    color: var(--thm-black);
  }

  b {
    font-weight: 500;
  }

  p {
    font-weight: 400;
  }
}

.right {
  position: sticky;
  top: calc(var(--header-h) + 24px);
  width: 50%;
  overflow: hidden;

  border-radius: 24px;
  box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid var(--thm-photo-stroke);

  height: calc(100vh - var(--header-h) * 2 - var(--chat-page-padding) * 1.5);

  @media (max-width: $tab) {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 0;
    border: unset;
  }

  &_bg {
    background-repeat: repeat;
    background-size: 280px;
    background-position: center -10%;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
    opacity: var(--chat-bg-opacity);
    mix-blend-mode: overlay;
  }

  &_chat {
    background-color: var(--chat-bg-color);
  }

  &:not(.right_chat) {
    @media (max-width: $tab) {
      display: none;
    }
  }
}

.chat_settings {
  width: 100%;
  height: 100%;
  background: var(--thm-bg);
}

.chats {
  margin-top: 12px;
  overflow: hidden;

  width: 100%;

  transform: translate3d(0, 0, 0);

  > div {
    overflow-y: auto;
    height: 100%;
  }
}
