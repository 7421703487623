$desktop-sm: 1024px;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 334px;
}

.graph {
  width: 100%;

  :global {
    .apexcharts-xaxis {
      transform: translate(2px, 0);
    }

    .apexcharts-tooltip {
      border-radius: 8px;
      box-shadow: var(--shadow-primary) !important;
      border: 1px solid var(--thm-grey-1) !important;
      background: var(--thm-card) !important;

      .apexcharts-tooltip-title {
        font-size: 11px !important;
        line-height: 13px;
        letter-spacing: 0.07px;
        color: var(--thm-silver-grey);

        border: 0 !important;
        background: 0 !important;

        margin-bottom: 0;
        padding: 8px !important;
      }

      .apexcharts-tooltip-series-group {
        padding: 0 8px 8px;
      }

      .apexcharts-tooltip-y-group {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .apexcharts-tooltip-marker {
        margin-right: 8px;

        :global(html[dir='rtl']) & {
          margin-right: unset;
          margin-left: 8px;
        }
      }

      .apexcharts-tooltip-text {
        width: 100%;
      }

      .apexcharts-tooltip-text-y-value {
        margin-left: 8px;
      }

      .apexcharts-tooltip-text-y-label {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.41px;
      }

      .apexcharts-tooltip-text-y-value {
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.08px;
      }
    }

    .apexcharts-text tspan {
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      fill: var(--thm-silver-grey);
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.35px;
}

.subtitle {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.categories_wrap {
  overflow-x: auto;
  width: calc(100% + 48px);
  margin-left: -24px;
  padding-left: 24px;

  @media (max-width: $desktop-sm) {
    width: calc(100% + 32px);
    margin-left: -16px;
    padding-left: 16px;
  }
}

.categories {
  display: flex;
  gap: 16px;
}

.category_btn {
  display: flex;
  align-items: flex-start;
  gap: 4px;

  transition: opacity 0.2s ease;

  > b {
    font-weight: 500;
    letter-spacing: -0.5px;
  }

  label {
    pointer-events: none;
  }
}

.category_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;

  span {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-black);
  }

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    color: var(--thm-silver-grey);
  }
}

.category_btn_excluded {
  opacity: 0.5;
}
