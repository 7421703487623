$desktop: 1024px;
$tab: 770px;
$mob: 520px;

.root {
  position: sticky;
  top: 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px var(--main-content-padding-x);
  gap: 36px;

  border-bottom: 1px solid var(--thm-grey-1);
  background-color: var(--thm-white);
  z-index: 100;
}

.right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.balance {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 16px;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: 16px;
  }

  @media (max-width: $mob) {
    display: none;
  }

  b {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: -0.408px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.dropdown_wrap {
  position: relative;
  z-index: 4;

  @media (max-width: $mob) {
    margin-left: auto;
  }
}

.left {
  display: flex;
  align-items: center;
  min-height: 36px;
  gap: 24px;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
}

.showroom_link {
  @media (max-width: $desktop) {
    a > div > span {
      display: none;
    }
  }
}

.balance,
.showroom_link,
.dropdown_wrap > button > div > span {
  @media (max-width: $tab) {
    display: none;
  }
}

.dropdown_wrap > button {
  @media (max-width: $tab) {
    width: 36px;
  }
}
