.reset_btn {
  position: absolute;
  top: 9px;
  left: 9px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--clr-black);

  :global(html[dir='rtl']) & {
    left: unset;
    right: 9px;
  }
}

.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;

  transition: 0.2s ease;
  border-radius: 8px;
  border: 1px dashed var(--thm-grey-2);
  background: var(--thm-white);

  max-height: 36px;

  p {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.2px;
    color: var(--thm-black);
  }

  p,
  span {
    white-space: nowrap;
  }

  > svg {
    transition: 0.2s color ease;
    color: var(--thm-silver-grey);
  }

  &:hover:not(.root_active):not(.root_filled) {
    border-color: var(--clr-blue);
    svg {
      color: var(--clr-blue);
    }
  }

  &_active {
    background: var(--clr-blue-15);
    border: 1px dashed var(--clr-blue);
  }

  &_search_active {
    .btn {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &_filled {
    background: var(--clr-blue-15);
    border: 1px solid var(--clr-blue);
  }

  &_reset > svg {
    visibility: hidden;
  }

  &_solid:not(.root_filled) {
    border-color: var(--thm-grey-1);
    border-style: solid;
  }
}

.value {
  color: var(--clr-blue);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  margin-left: 4px;
  padding-left: 8px;
  position: relative;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    padding-left: unset;

    margin-right: 4px;
    padding-right: 8px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 0;
    width: 1px;
    height: 20px;

    background: var(--clr-blue-15);

    :global(html[dir='rtl']) & {
      left: unset;
      right: 0;
    }
  }
}

.search_field {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: var(--thm-black);

  &::placeholder {
    color: var(--thm-silver-grey);
  }
}
