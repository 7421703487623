.modal_container {
  display: flex;
  flex-direction: column;
}

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
}

.field {
  position: relative;
  margin-bottom: 12px;
}

.edit_btn {
  position: absolute;

  top: 50%;
  right: 0%;
  transform: translate(-12px, -50%);

  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  text-align: right;

  color: var(--clr-blue);

  :global(html[dir='rtl']) & {
    right: unset;
    left: 0%;

    transform: translate(12px, -50%);
  }
}

.label {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
  text-align: left;

  margin-top: 4px;
  padding-left: 12px;

  color: var(--thm-silver-grey);

  :global(html[dir='rtl']) & {
    padding-left: unset;
    padding-right: 12px;

    text-align: right;
  }
}

.text {
  margin-top: 24px;

  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
}

.footer {
  margin-top: auto;
  padding-top: 24px;

  button + button {
    margin-top: 12px;
  }
}

.hours {
  margin-bottom: 12px;
}
