.root {
  position: relative;

  > svg {
    color: var(--thm-silver-grey);
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);

    :global(html[dir='rtl']) & {
      right: 12px;
      left: unset;
    }
  }

  input {
    width: 100%;
    padding: 12px 12px 12px 40px;

    border: 1px solid var(--thm-silver-grey-2);
    background: var(--thm-grey-1);
    border-radius: 12px;

    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: var(--thm-black);

    transition: 0.2s all ease;

    &:hover,
    &:focus {
      border: 1px solid var(--clr-blue);
    }

    &::placeholder {
      color: var(--thm-silver-grey);
    }
  }
}

.root_fullwidth {
  width: 100%;
}

.root_compact {
  input {
    padding: 8px 32px 8px 32px;
    border-radius: 8px;
    border: 1px solid var(--thm-silver-grey-2);
    line-height: 18px;

    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.24px;
  }
}

.root_outlined {
  > svg {
    color: var(--thm-black);
  }

  input {
    background: var(--thm-card);
    border: 1px solid var(--thm-grey-1);
  }
}

.root_highlighted {
  input {
    background-color: var(--clr-blue-15);
    border-color: var(--clr-blue-15);
  }
}

.clear_btn {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-silver-grey);
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease;

  &_visible {
    opacity: 1;
    pointer-events: auto;
  }

  &,
  svg {
    width: 22px;
    height: 22px;
  }

  :global(html[dir='rtl']) & {
    right: unset;
    left: 12px;
  }
}
