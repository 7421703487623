$tab: 770px;

.root {
  position: sticky;
  top: 0px;

  transition: 0.2s all ease;

  padding: 21px 16px;
  z-index: 1;

  background-color: var(--thm-white);
  border-bottom: 1px solid var(--thm-grey-1);

  z-index: 2;

  @media (max-width: $tab) {
    padding: 4px 12px 4px 6px;
  }
}

.back {
  min-height: 36px;
  display: flex;
  align-items: center;

  @media (min-width: #{$tab + 1}) {
    display: none;
  }
}

.row {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  gap: 12px;

  @media (max-width: $tab) {
    display: none;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.title {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;

  color: var(--thm-black);
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--thm-black);

  &,
  svg {
    width: 24px;
    height: 24px;
  }

  svg {
    :global(html[dir='rtl']) & {
      transform: scaleX(-1);
    }
  }
}
