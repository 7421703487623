$mob: 770px;

.modal_container.modal_container_imp {
  border-radius: 24px;
  border: 1px solid var(--thm-grey-1);
}

.box {
  text-align: center;
  margin-bottom: 24px;
}

.img {
  display: block;
  margin: 0 auto 16px;
  width: 278px;

  img,
  svg {
    max-width: 100%;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.35px;
  margin-bottom: 8px;
}

.text,
.tip {
  margin-bottom: 24px;

  @media (max-width: $mob) {
    margin-bottom: 36px;
  }
}

.text {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.3199999928474426px;

  padding: 0 24px;
}

.input {
  position: relative;
  text-align: left;

  input {
    padding-right: 8em !important;

    :global(html[dir='rtl']) & {
      padding-right: 12px !important;
      padding-left: 8em !important;
    }
  }
}

.tip_wrap {
  width: 100%;
  text-align: right;
  margin-top: 4px;

  :global(html[dir='rtl']) & {
    text-align: left;
  }
}

.tip {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;

  color: var(--clr-blue);
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (max-width: $mob) {
    flex-direction: column-reverse;
  }

  button {
    flex: 1;
    @media (max-width: $mob) {
      width: 100%;
    }
  }
}

.help_root {
  @media (min-width: #{$mob + 1}) {
    width: 672px;
  }
}

.help_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-bottom: 40px;
}

.help_title {
  font-size: 28px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0.36000001430511475px;
}

.close_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mob) {
    display: none;
  }

  &,
  svg {
    width: 28px;
    height: 28px;
  }
}

.help_box {
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .text {
    margin-bottom: 24px;
  }

  .img {
    margin-bottom: 0;
  }
}
