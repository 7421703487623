$tab: 770px;

div.container_common {
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;
  width: 540px;
  height: 100%;
  max-height: 100%;
  margin-left: auto;
  border: 0;
  border-left: 1px solid var(--thm-grey-1);

  border-radius: 0;
  box-shadow: unset;

  transition: 0.2s ease-in-out;
  opacity: 0;
  transform: translateX(101%);

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;

    transform: translateX(-101%);
  }
}

[data-open='true'] {
  div.container_common {
    opacity: 1;
    transform: translateX(0);
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.36px;
}
