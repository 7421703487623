$tab: 770px;

.root {
  position: relative;
  display: flex;
  align-items: center;
  padding: var(--padding);
  gap: var(--gap);

  border-radius: 16px;

  transition: background 0.2s ease;

  @media (max-width: $tab) {
    padding: 12px;

    --img-width: 80px;
  }

  position: relative;

  &:hover {
    background: var(--thm-grey-1);
  }

  &_skeleton {
    max-height: 88px;
  }
}

.root_active {
  background: var(--thm-grey-1);

  &::after {
    background-color: transparent;
  }
}

.root_offer {
  .read_icon {
    svg {
      color: var(--thm-gold);
    }
  }

  .date {
    color: var(--thm-gold);
  }

  .unread {
    background-color: var(--thm-gold);
    color: var(--thm-light-gold);
  }
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.img_wrap {
  position: relative;
  overflow: hidden;
  height: 64px;

  border-radius: 10px;
}

.img_ad {
  min-width: var(--img-width);
  width: var(--img-width);
  height: 64px;

  object-fit: cover;
  border-radius: 10px;

  box-shadow: 0 0 0 1px var(--thm-photo-stroke);

  @media (max-width: $tab) {
    height: 60px;
    border-radius: 12px;
  }
}

.img_status {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  padding: 4px 2px;

  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: var(--clr-white);
  text-align: center;

  background: var(--clr-primary);

  &_grey {
    background: var(--thm-silver-grey);
  }
}

.avatar {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  box-shadow: 0 0 0 2px var(--thm-white);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: calc(100% - 12px - 85px);

  @media (max-width: $tab) {
    width: calc(100% - 12px - 80px);
  }
}

.top {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.3em;
  margin-bottom: 1px;
}

.ad_name {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;

  color: var(--thm-black);
}

.ad_status {
  padding: 2px 4px;
  border-radius: 4px;

  background-color: var(--thm-grey-1);
  color: var(--thm-silver-grey);

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.title {
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
}

.read_icon {
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--clr-green);
}

.date {
  text-align: right;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: var(--thm-silver-grey);

  @media (max-width: $tab) {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: unset;
  }
}

.content {
  display: flex;
  align-items: center;
}

.sender {
  font-weight: 600 !important;
}

.text {
  max-width: calc(100% - 32px);
  width: 100%;

  &,
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-black);

    @media (max-width: $tab) {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.24px;
    }
  }
}

.stats {
  display: flex;
  align-items: center;

  > li {
    position: relative;
    white-space: nowrap;

    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.41px;
    color: var(--thm-silver-grey);

    &:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:not(:first-child) {
      padding-left: 20px;

      :global(html[dir='rtl']) & {
        padding-left: unset;
        padding-right: 20px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;

        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: var(--thm-silver-grey);

        :global(html[dir='rtl']) & {
          left: unset;
          right: 8px;
        }
      }
    }
  }
}

.offer {
  display: flex;
  align-items: center;
  gap: 4px;

  &_price {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;

    color: var(--thm-gold);
  }

  > li {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last_msg {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.unread {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  align-self: flex-end;

  min-width: 22px;
  height: 22px;
  padding: 0 7px;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
  color: var(--clr-white);
  text-align: center;

  background: var(--clr-primary);
  border-radius: 13px;
}

.preview_img_text {
  display: flex;
  align-items: center;
  gap: 0.3em;
  margin-top: 2px;
}

.preview_img {
  min-width: 20px;
  width: 20px;
  height: 20px;

  object-fit: cover;
  border-radius: 2px;
}
