.root {
  --header-h: 72px;
  background: var(--thm-white-bg);
}

.header {
  background: var(--thm-white);
  box-shadow: 0 1px 0 0 var(--thm-grey-1);

  padding: 12px 0;

  z-index: 100;
  position: sticky;
  top: 0;
}

.header_inner {
  min-height: 48px;
  display: flex;
  align-items: center;
}

.logo_link {
  display: flex;
  align-items: center;
  gap: 12px;

  img {
    width: 119px;
    height: auto;
  }
}

.header_row {
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.exit_btn {
  margin-left: auto;
  position: relative;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }
}

.dropdown_btn {
  white-space: nowrap;

  &_red {
    color: var(--clr-primary);
  }
}
