$desktop-lg: 1500px;

.root {
  padding: 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;

  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 16px;
  transition: 0.2s ease;

  &:hover {
    border: 1px solid var(--thm-grey-1);
    background: var(--thm-white);
    box-shadow: var(--shadow-primary);

    .col {
      &:nth-child(3),
      &:nth-child(4) {
        background: var(--thm-card);
      }
    }

    .contact svg:last-child {
      opacity: 1;
    }
  }
}

.col {
  position: relative;
  flex: 1;

  &:first-child {
    min-width: 170px;
    max-width: 170px;
  }

  &:nth-child(2) {
    max-width: 485px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }

  &:nth-child(3) {
    max-width: 324px;
    margin-left: auto;

    :global(html[dir='rtl']) & {
      margin-left: unset;
      margin-right: auto;
    }
  }

  &:nth-child(4) {
    max-width: 240px;
  }

  &:nth-child(3),
  &:nth-child(4) {
    padding: 12px;
    min-height: 130px;

    background: var(--thm-white);
    border: 1px solid var(--thm-grey-1);
    border-radius: 8px;
  }
}

.img {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75.294%;
  }
}

.img_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid var(--thm-photo-stroke);
  object-fit: cover;

  background-color: var(--thm-card);
}

.advert_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.advert {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.41px;
  color: var(--thm-black);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: $desktop-lg) {
    max-width: 200px;
  }
}

.advert_status {
  padding: 2px 4px;
  border-radius: 4px;

  background-color: var(--thm-grey-1);
  color: var(--thm-silver-grey);

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  @media (max-width: $desktop-lg) {
    position: absolute;
    top: 4px;
    left: -177px;

    padding: 4px 6px;
  }
}

.view_badge {
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background: var(--clr-primary);
  border: 1px solid var(--thm-photo-stroke);
}

.date {
  color: var(--thm-silver-grey);
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  margin-top: auto;
}

.specs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;

  span {
    color: var(--thm-silver-grey-1);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.41px;
    transform: translateY(-1px);
  }
}

.col_label {
  color: var(--thm-silver-grey);
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  align-items: center;
  gap: 16px;

  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  > p {
    color: var(--thm-silver-grey);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    min-width: 124px;
  }

  > b {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;
  }
}

.contact {
  display: flex;
  align-items: center;
  gap: 4px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover svg:last-child {
    color: var(--thm-black);
  }

  svg {
    width: 16px;
    height: 16px;

    &:last-child {
      opacity: 0;
      transition: 0.2s ease;
      color: var(--thm-silver-grey);
    }
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 163px;
  }
}

.link {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
