.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-bottom: 16px;
  > li:not(:last-child) {
    margin-bottom: 12px;
  }
}

.status_row {
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.status_color {
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.status_count {
  margin-left: auto;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }
}

.bar {
  display: flex;
  margin-bottom: 16px;

  span {
    height: 24px;
  }
}
