$desktop: 1300px;

.root {
  padding: 24px;

  border-radius: 16px;
  background: var(--thm-white);
  box-shadow: var(--shadow-primary);
  border: 1px solid var(--thm-grey-1);

  @media (max-width: $desktop) {
    padding: 16px;
  }
}

.score {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;

  b {
    font-size: 48px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0.38px;
  }
}

.score_of {
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: var(--thm-silver-grey);
  display: block;
}

.score_count {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
}

.estimates {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > li:not(:last-child) {
    margin-bottom: 4px;
  }
}

.estimate {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.41px;
}

.stars {
  display: flex;
}

.star {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--thm-silver-grey-2);

  &,
  svg {
    width: 16px;
    height: 16px;
  }
}

.star_fill {
  color: var(--clr-orange);
}

.estimate_bar {
  flex: 1;
  height: 4px;
  position: relative;

  border-radius: 2px;
  background: var(--thm-silver-grey-2);
}

.estimate_bar_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  border-radius: 2px;
  background: var(--thm-silver-grey);
}

.estimate_count {
  min-width: 30px;
  text-align: right;

  :global(html[dir='rtl']) & {
    text-align: left;
  }
}
