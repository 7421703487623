$tab: 770px;

.root {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;

  border-radius: 16px;
  border: 1px solid var(--thm-grey-1);
  background: var(--thm-white);
  transition: 0.2s ease;

  @media (max-width: $tab) {
    flex-direction: column;
  }

  &:hover {
    background: var(--thm-card);
    box-shadow: var(--shadow-primary);

    .actions_desktop {
      width: auto;
    }
  }
}

.img {
  position: relative;
  min-width: 200px;
  width: 200px;
  overflow: hidden;

  border: 0.5px solid var(--thm-photo-stroke-color);
  border-radius: 8px;

  @media (max-width: $tab) {
    min-width: unset;
    width: 100%;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 75%;

    @media (max-width: $tab) {
      padding-top: 52.46%;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.img_snap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  width: calc(100% - 216px);
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: $tab) {
    width: 100%;
    gap: 16px;
    justify-content: flex-start;
    height: auto;
  }
}

.footer,
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: calc(100% - 150px);
  flex: 1;

  @media (max-width: $tab) {
    max-width: 100%;
  }

  b {
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.41px;

    color: var(--thm-black);
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: var(--thm-silver-grey);
  }

  b,
  p {
    overflow: hidden;
  }

  b {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 40px;
    word-break: break-word;
  }
}

.work_time,
.phone {
  display: flex;
  flex-direction: column;
  gap: 2px;

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    text-align: left;

    color: var(--thm-silver-grey);
  }

  b {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-align: left;

    color: var(--thm-black);
  }
}

.work_time {
  margin-left: auto;

  :global(html[dir='rtl']) & {
    margin-left: unset;
    margin-right: auto;
  }
}

.actions {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;

  transition: 0.2s all ease;

  &_desktop {
    @media (max-width: $tab) {
      display: none;
    }
  }

  &_mobile {
    width: 100%;

    @media (min-width: #{$tab + 1}) {
      display: none;
    }
  }
}

.delete {
  button {
    width: 36px;
  }
}
