.card {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.title {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.41px;
  color: var(--thm-silver-grey);

  span {
    &:last-of-type {
      margin-left: auto;
    }
  }
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  transition: 0.2s ease all;

  &:hover {
    &,
    svg {
      color: var(--clr-blue);
    }
  }

  svg {
    :global(html[dir='rtl']) & {
      transform: scaleX(-1);
    }
  }
}

.tooltip_overlay {
  width: 260px;

  div {
    text-align: center;
  }
}

.amount_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.amount {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.35px;
}

.trend {
  display: flex;
  align-items: center;
  gap: 2px;

  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  color: var(--clr-green);
  &_negative {
    color: var(--clr-primary);

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
}
.trend_negative {
}

.conversion {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: var(--thm-silver-grey);
  }
}

.graph {
  margin-top: auto;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info {
  color: var(--thm-silver-grey);
  &:hover {
    color: var(--thm-black);
  }
}
