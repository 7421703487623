$mob: 770px;

.root {
  margin-top: 24px;

  @media (max-width: $mob) {
    margin-top: 8px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media (max-width: $mob) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.vin_help {
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: var(--clr-blue);

  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  :global(html[dir='rtl']) & {
    right: unset;
    left: 12px;
  }
}

.text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: var(--thm-silver-grey);
  margin-bottom: 20px;
}

.field {
  position: relative;

  input {
    padding-right: 8em !important;

    :global(html[dir='rtl']) & {
      padding-right: 12px !important;
      padding-left: 8em !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
